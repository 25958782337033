<template>
  <footer class="mt-3 new_footer_area" :style="`background-image: url('${imageDomain}/assets/img/newfooterbg.jpg?speedsize=w_1600');`">
    <div class="footer_body" v-if="device === 'desktop'  && showMainFooter">
      <div class="container">
        <div class="row">
          <div class="col-6 col-sm-12" :class="`col-md-${pageCount1 < 7 ?  Math.round(12/pageCount1) : 2}`" v-for="(cat, inx) in pageList1" :key="inx">
            <footer-category-one-item :dataByCategory="cat"/>
          </div>
        </div>
      </div>
    </div>

    <div class="footer_body" v-if="pageCount2 && device === 'desktop' && showMainFooter">
      <div class="container">
        <div class="row">
          <div class="col-6 col-sm-12" :class="`col-md-${12/pageCount2}`" v-for="(cat, inx) in pageList2" :key="inx">
            <footer-category-one-item :dataByCategory="cat"/>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_body" v-if="device === 'mobile'  && showMainFooter">
      <div class="container">
        <footer-collapse v-for="(item, inx) in pageList" :key="inx" :oneCategoryItem="item" :index="inx"/>
      </div>
    </div>

    <div class="footer_body footer_top" v-if="device === 'desktop' && showCustomSubjectFooter">
      <div class="container">
        <div class="row d-flex justify-content-start" >
          <custom-footer-column v-for="(subject, i) in customFooterSubjects" :key="i"
            :subject="subject"
            :show="showCustomSubjectFooter"
          />
          <div class="col-2 col-md-2" v-for="(cat, inx) in pageList1" :key="inx + customFooterSubjects.length">
            <footer-category-one-item :dataByCategory="cat"/>
          </div>
          <!-- <div class="px-0">
            <div class="col-sm-12">
              <div class="footer">
                <div class="footer_logo d-flex"><img :src="srcLogo" alt="logo" title="" class="m-auto"/></div>
                <div class="d-flex">
                  <ul class="footer_social d-flex m-auto">
                    <li v-if="showTwitterLink"><a :href="twitterLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-x-twitter"></i></a></li>
                    <li v-if="showFacebookLink"><a :href="facebookLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://g.co/kgs/ApYvyz" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-google"></i></a></li>
                    <li v-if="showInstagramLink"><a :href="instagramLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-instagram"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <div class="footer_body">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-3" >
            <div class="footer">
              <div class="footer_logo m-auto"><img :src="srcLogo" alt="logo" title=""  width="300" height="66"/></div>
              <h5 class="text-center">{{$t("footer.follow-us-on")}}</h5>
              <ul class="footer_social text-center">
                  <li><a :href="twitterLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-x-twitter"></i></a></li>
                  <li><a :href="facebookLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-facebook-f"></i></a></li>
                  <li><a href="https://g.co/kgs/ApYvyz" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-google"></i></a></li>
                  <li><a :href="instagramLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-instagram"></i></a></li>
                  <!-- <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li> -->
                  <!-- <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li> -->
              </ul>
            </div>
          </div>
          <div class="col-6 col-sm-12 col-md-9" v-if="showMemberLogin">
            <div class="footer row">
              <div class="col-12 col-sm-6 col-md-6 text-center show-message form-group">{{$t("footer.member-login-alert")}}</div>
              <div class="col-12 col-sm-6 col-md-4 text-center">
                <member-register/>
              </div>
              <div class="col-12 col-sm-6 col-md-2 text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="copyright_area"><p>{{ $t("footer.all-reserved-title", {year: new Date().getFullYear(), agencyName: whiteLabel.name}) }}</p></div>
  </footer>
</template>

<script>
import TemplateFooter from './TemplateFooter';

export default {
  name: 'FooterTheme0',
  mixins: [TemplateFooter],
  components: {
    MemberRegister: () => import('@/components/atoms/MemberRegister'),
    FooterCategoryOneItem: () => import('@/components/HeaderFooter/FooterCategoryOneItem'),
    FooterCollapse: () => import('@/components/HeaderFooter/FooterCollapse'),
    CustomFooterColumn: () => import('@/components/HeaderFooter/customFooterColumn/CustomFooterColumnTheme0'),
  },
};
</script>

<style>
  body.flat-404 footer{
    margin-bottom: 0;
    color: white;
  }
  body.flat-404 .footer-btm .copy ul li a {
    color: white;
  }
  .row_title {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 20px;
  }
</style>

<style scoped>
  .show-message{
    font-size: 1.5rem;
    color: white;
  }
  .link-nDetail .short-links .list-group-item{
    padding: 0;
  }
  footer .list-group,
  .link-nDetail .footer-social ul {
    padding: 0;
  }
  footer .shortLink-wrapper:not(:last-child){
    border-left: 1px solid white;
  }
  footer .shortLink-wrapper{
    padding: 0 1.5rem;
  }
  .new_footer_area{
    background-image: url('/assets/img/newfooterbg.jpg');
  }
  .footer_body .footer .footer_logo img{
    height: auto;
  }
</style>
